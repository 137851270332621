/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

const About = (): JSX.Element => {
    return (
        <Layout>
            <Helmet title="StitchedPunks – About" meta={[{ name: 'description', content: 'StitchedPunks – About' }]} />

            <div id="about" className="alt">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h1>About</h1>
                        </header>
                        <div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <h2>Creators</h2>
                                        <p>
                                            Hello there! We are Marisa and{' '}
                                            <a href="https://florianblum.com">Florian Blum</a> from Germany.{' '}
                                            <span role="img" aria-label="waving hand">
                                                👋
                                            </span>{' '}
                                            StitchedPunks is our joint project that combines several of our interests:
                                            Embroidery, handicrafting, art, web design and blockchain software
                                            development.{' '}
                                            <span role="img" aria-label="school teacher">
                                                👩🏻‍🏫
                                            </span>{' '}
                                            Marisa is an elementary school teacher who learned embroidery from her
                                            grandma when she was eight years old.{' '}
                                            <span role="img" aria-label="student with square academic cap">
                                                👨🏻‍🎓
                                            </span>{' '}
                                            Florian is a blockchain researcher doing his PhD at the University of
                                            Duisburg-Essen.
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <StaticImage
                                                src="../assets/images/about/marisa-and-flo.jpg"
                                                alt="Marisa and Florian in their wedding outfits as embroidery artwork"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="major" />
                        <div>
                            <h2>Idea</h2>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <p>
                                            There are a lot of wonderful derivative artworks that build upon the
                                            creativity of the original{' '}
                                            <a href="https://www.larvalabs.com/cryptopunks">Larva Labs CryptoPunks</a>.
                                            But there are not many art projects that build upon the technological
                                            advantages the underlying blockchain offers. Because the original
                                            CryptoPunks are NFTs based on a smart contract, it is very easy to build
                                            software that is compatible with it. As the ownership of CryptoPunks can be
                                            checked from within another smart contract ("on-chain") it is possible to
                                            make it a requirement to own a CryptoPunk before ordering the corresponding
                                            StitchedPunk. This means, nobody can order the StitchedPunk of your
                                            CryptoPunk.
                                        </p>
                                        <p>
                                            We believe this feature makes the StitchedPunks project very appealing.
                                            Suddenly, a StitchedPunk represents and embodies the physical ownership as a
                                            counterpart to a completely digital CryptoPunk artwork. This is very
                                            different from printing a digital artwork because there is no ownership
                                            connected or proven with it.
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <Link to="/details/9670">
                                                <StaticImage
                                                    src="../assets/images/about/idea-companion.jpg"
                                                    alt="CryptoPunk #9670 and its corresponding StitchedPunk"
                                                    className="inverted-hover"
                                                />
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="major" />
                        <div>
                            <h2>Manufacturing</h2>
                            <p>
                                StitchedPunks are created from high-quality materials, preferrably from natural
                                resources as sustainability is very important to us. Materials of good quality not only
                                make the fabrication easier, they also improve the durability of the overall product. It
                                also ensures that all artworks follow a consistent look.
                            </p>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <h4>Wooden Frame</h4>
                                        <p>
                                            Every StitchedPunk starts with a strong frame. Our embroidery frames are
                                            from <a href="https://www.prym-group.com/">Prym</a>, the oldest family
                                            business here in Germany with a long tradition in products for crafts. It is
                                            made from three layers of hard and durable beech wood. The adjusting screw
                                            is made of brass and keeps the tension of the fabric. Afterwards it can also
                                            be used as a hanger for putting your artwork on the wall. The frame has a
                                            diameter of 13cm (~5.1″) and a thickness of 8mm (~0.3″).
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <StaticImage
                                                src="../assets/images/about/manufacturing-frame.jpg"
                                                alt="Preparation for creating StitchedPunk #9670"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4>Base Fabric</h4>
                                <p className="with-margin">
                                    The base fabric is made by <a href="https://www.dmc.com">DMC</a> in France. Since
                                    1746 they are creating precisely woven embroidery fabrics made from 100% cotton. We
                                    are using their Aida fabric with a friendly ecru color hue to have a more natural
                                    look and a density of 14 counts per inch.
                                </p>
                            </div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <h4>Threads and Colors</h4>
                                        <p>
                                            The embroidery thread is Stranded Cotton from{' '}
                                            <a href="https://anchorcrafts.com">Anchor</a>. It is made of six strands of
                                            Egyptian cotton and is available in many different colors. When crafting
                                            StitchedPunks we carefully select the most appropriate color for each pixel
                                            of the reference image. We do this by comparing multiple shades of the same
                                            thread color, matching them against the pixel color of the original
                                            CryptoPunk artworks and considering the color temperature of the daylight.
                                        </p>
                                        <p>
                                            When a thread color has been selected for a given pixel color, this mapping
                                            will be used for all future StitchedPunks where the same pixel color occurs.
                                            This reduces any variation in the color and improves the consistency of all
                                            artworks.
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <StaticImage
                                                src="../assets/images/about/manufacturing-threads.jpg"
                                                alt="Marisas box with embroidery threads of various colors"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <h4>Cross Stitch × Pixel Art</h4>
                                        <p>
                                            Using the cross stitch technique for pixel art is a match made in heaven.
                                            The uniform pattern of the base fabric allows for a consistent embroidery
                                            where each cross stitch represents exactly one pixel. For creating an even
                                            more fine-grained image, the six-stranded thread is halved. Those
                                            three-stranded threads help to highlight smaller details and lead to more
                                            accurate results.
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <Link to="/details/9670">
                                                <StaticImage
                                                    src="../assets/images/about/manufacturing-cross-stitch.jpg"
                                                    alt="Close-up of cross stitches in StitchedPunk #9670"
                                                    className="inverted-hover"
                                                />
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <h4>Back Cover</h4>
                                        <p>
                                            The back of the frame is covered by a sheet of felt. This prevents light to
                                            enter from behind, hides the thread ends inside and gives the StitchedPunk a
                                            cleaner finish.
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <StaticImage
                                                src="../assets/images/about/manufacturing-backside.jpg"
                                                alt="Back surface closed with a sheet of felt"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>
                            <span role="img" aria-label="flame icon">
                                🔥
                            </span>{' '}
                            <i>
                                Eager to see a few finished StitchedPunks? Take a look at the{' '}
                                <Link to="/gallery">gallery</Link>.
                            </i>
                        </p>
                        <hr className="major" />
                        <div>
                            <h2>Order Process</h2>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-1 center">
                                        <span className="icon fa-shopping-cart order-icons"></span>
                                    </div>
                                    <div className="col-11">
                                        <p>
                                            <i>So, how do you get your own StitchedPunk?</i> For ordering a
                                            StitchedPunk, you have to own the corresponding CryptoPunk. Ownership can be
                                            proven for punks that are directly in your wallet (e.g., when you manage it
                                            directly on the CryptoPunk website) or owned as a{' '}
                                            <a href="https://wrappedpunks.com/">WrappedPunk</a> for ERC-721 markets.
                                        </p>
                                        <p>
                                            To start the order process, you must have the{' '}
                                            <a href="https://metamask.io">MetaMask plugin</a> installed for your
                                            browser, a working crypto wallet with your punks and some Ether. Then head
                                            over to <Link to="/profile">your profile</Link> and visit the detail page
                                            for one of your punks to proceed.
                                        </p>
                                        <p>
                                            We are asking for your email address during your order. This is necessary to
                                            contact you when the StitchedPunk is ready and to ask for your desired
                                            shipping address. We are completely aware that trust is a crucial aspect for
                                            every blockchain project. Therefore we want to emphasize that we will
                                            process and save your personal data with the highest care. Your email
                                            address will only be used to inform you about updates regarding your ordered
                                            StitchedPunk and your shipping address is required for sending you the
                                            finished StitchedPunk via mail. In case you do not want to share your
                                            private address, this is perfectly fine and we can work out a solution
                                            (e.g., sending your parcel to a work address or post office box). Free
                                            worldwide shipping is included with your order.
                                        </p>
                                        <p>
                                            When you are connected with your wallet that contains your CryptoPunk, an
                                            initial ownership check is carried out. After entering your email, you are
                                            ready to submit your order. The current price will be shown before you push
                                            the order button and a MetaMask popup will show up. When you confirm your
                                            order, it will be saved on the{' '}
                                            <span className="pre-format">
                                                <a href="https://etherscan.io/address/0x9f4263370872b44ef46477dc9bc67ca938e129c6">
                                                    StitchedPunksShop
                                                </a>
                                            </span>{' '}
                                            smart contract and you will receive the transaction hash. Both elements
                                            serve as your order receipt and you can immediately check the status of your
                                            order on the detail page of this punk. The source code of the smart contract
                                            can be viewed on{' '}
                                            <a href="https://etherscan.io/address/0x9f4263370872b44ef46477dc9bc67ca938e129c6#code#F3#L1">
                                                Etherscan
                                            </a>{' '}
                                            and <a href="https://github.com/ColdDevil/com.stitchedpunks">GitHub</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-1 center">
                                        <span className="icon fa-cogs order-icons"></span>
                                    </div>
                                    <div className="col-11">
                                        <p>
                                            <i>Get me the embroidery frame!</i> After your order has been saved, the
                                            manufacturing process begins. When we start to craft your StitchedPunk, you
                                            will receive an email and we update the order status of your punk in the
                                            smart contract. Just take a look at its detail page for the current status.
                                            You can also follow the official{' '}
                                            <a href="https://twitter.com/StitchedPunks">
                                                @StitchedPunks Twitter account
                                            </a>{' '}
                                            to see which punk is currently created and a few details of the
                                            manufacturing process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-1 center">
                                        <span className="icon fa-truck order-icons"></span>
                                    </div>
                                    <div className="col-11">
                                        <p>
                                            <i>The day you have been waiting for:</i> Your StitchedPunk is ready and
                                            will be shipped! We carefully put your artwork in a wrapping tissue and use
                                            padding inside the cardboard box. But no bubble-wrap or foam will be used as
                                            there is already too much plastic in our environment. There are great
                                            eco-friendly alternatives such as organic materials or recycled paper.
                                        </p>
                                        <p>
                                            You will be notified about the shipment of your StitchedPunk and immediately
                                            receive a tracking number. The order status will also be updated on your
                                            status page (more precisely, in the smart contract).
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-1 center">
                                        <span className="icon fa-magic order-icons"></span>
                                    </div>
                                    <div className="col-7">
                                        <p>
                                            <i>That was the doorbell:</i> Your StitchedPunk finally arrives! Now you can
                                            think about where to put your artwork on display. Use the brass screw to
                                            hang it on the wall, place it on your desk or put it on a shelf. Or you keep
                                            it safe and sound in your box, only to take it out every now and then.
                                        </p>
                                        <p>
                                            Feel free to send us a photo of your StitchedPunk and how you chose to
                                            arrange it. We can put it on the detail page of your punk and in our
                                            gallery! You can also tag the{' '}
                                            <a href="https://twitter.com/StitchedPunks">StitchedPunk Twitter account</a>{' '}
                                            in a Tweet or <Link to="#contact">send us an email</Link>. In any case: We
                                            would love to hear from you!{' '}
                                            <span role="img" aria-label="heart icon">
                                                ❤️
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <StaticImage
                                                src="../assets/images/about/order-received.jpg"
                                                alt="Idea on how to place StitchedPunks in your home"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="major" />
                        <div>
                            <h2>StitchedPunk NFT</h2>
                            <div className="box alt">
                                <div className="grid-wrapper">
                                    <div className="col-8">
                                        <p>
                                            Just as a CryptoPunk NFT represents the ownership of the digital artwork,
                                            the StitchedPunk NFT refers to the physical product. It contains metadata
                                            for your punk, such as the creation date and the production sequence number
                                            (e.g., <Link to="/details/9670">StitchedPunk #9670</Link> was the very
                                            first!). When your order is shipped, the StitchedPunk NFT will be minted to
                                            the wallet, your order was sent from. This will automatically update the
                                            order status of your StitchedPunk.
                                        </p>
                                        <p>
                                            In the future, the NFT will also allow you to customize the detail page of
                                            your StitchedPunk a bit. If you like, you can update the physical location
                                            of your StitchedPunk (maybe we can show a map with all the StitchedPunks
                                            distributed on the world?) or give your punk a unique nickname! I'm sure you
                                            already know a good name or who your punk resembles.
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="image fit">
                                            <Link to="/details/9670">
                                                <StaticImage
                                                    src="../assets/images/stitchedpunk-nft-9670.jpg"
                                                    alt="NFT artwork for StitchedPunk #9670"
                                                    className="inverted-hover"
                                                />
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default About;
